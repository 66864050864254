import { ItemType } from 'modules/help'

export const accordion = [
  {
    id: 'faq-0',
    title: 'O condomínio pode solicitar crédito?',
    item: [
      {
        id: '1',
        type: 'text' as ItemType,
        content:
          'Sim, desde que aprovado em assembleia. Se a maioria estiver de acordo com a tomada de crédito, não há problemas em solicitá-lo..'
      }
    ]
  },
  {
    id: 'faq-1',
    title: 'O crédito para condomínio pode ser utilizado para qualquer finalidade?',
    item: [
      {
        id: '1',
        type: 'text' as ItemType,
        content:
          'Sim. É possível usar o crédito para condomínio para investimentos, despesas com obras emergenciais, fluxo de caixa e qualquer outra finalidade em benefício do patrimônio.'
      }
    ]
  },
  {
    id: 'faq-2',
    title: 'Crédito para condomínio precisa de fiador ou garantia?',
    item: [
      {
        id: '1',
        type: 'text' as ItemType,
        content: 'Não, nenhuma garantia ou fiador é exigido. O tomador do crédito é o próprio condomínio.'
      }
    ]
  },
  {
    id: 'faq-3',
    title: 'Quais são as condições para que o crédito possa ser solicitado?',
    item: [
      {
        id: '1',
        type: 'text' as ItemType,
        content:
          'O condomínio pode parcelar o crédito em até 90 meses. As taxas de juros são definidas de acordo com a finalidade, a inadimplência e o perfil do condomínio e estão sujeitas à análise.'
      }
    ]
  },
  {
    id: 'faq-4',
    title: 'Qual o prazo para análise e liberação do crédito para condomínio?',
    item: [
      {
        id: '1',
        type: 'text' as ItemType,
        content:
          'Depois de fornecer todos os dados necessários, a análise é imediata e a liberação do crédito fica sujeita à confirmação. Geralmente, o pagamento é feito em até 48 horas.'
      }
    ]
  }
]
